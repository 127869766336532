import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import appInsights from "./appInsights"
import { ThemeProvider as MuiThemeProvider } from "@mui/system"
import { MuiCostcoTheme } from "@costcolabs/forge-components"
import Divider from "@mui/material/Divider"

import Header from "./header"
import "./layout.css"
import Footer from "./footer"

appInsights.trackPageView()

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  MuiCostcoTheme.typography.caption = {
    fontSize: "", // required for stepper to work
  }
  return (
    <>
      {/* <div style={{display:"block"}}> */}
      <MuiThemeProvider theme={MuiCostcoTheme}>
        {/* // TODO - Add required header */}
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <Divider orientation="horizontal" aria-hidden="true" />
        <div
          className="maincontent"
          role="main" // Indicate this is the main content of the page
        >
          <main aria-label="Scheduling wizard">{children}</main>
        </div>
        <Footer aria-label="Footer" />
      </MuiThemeProvider>
      {/* </div> */}
    </>
  )
}

export default Layout
