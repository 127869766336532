import * as React from "react"
import { useState, useEffect, createContext } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { HomePageLink } from "@costcolabs/forge-digital-components"

import "./header.css"

function Header({ siteTitle }) {
  return (
    <div className="temp_header">
      <header>
        <nav aria-label="Main navigation to go to Costco Home">
          <div className="home_logo">
            <Link to="/" aria-label={`${siteTitle} Home`} className="logo-link">
              <StaticImage
                alt="Costco Pharmacy logo"
                height={40}
                style={{
                  //  margin: 0
                  // margin: "50px 0px 0px 0px"
                  margin: "50px 0 0 20%",
                  backgroundColor: "#ffffff !important",
                }}
                src="../images/LogoPharmacy.svg"
                placeholder="none"
              />
            </Link>
          </div>
        </nav>
      </header>
    </div>
  )
}
export default Header
