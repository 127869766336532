import React from "react"
import Layout from "./layout"
import appInsights from "./appInsights"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      appInsights.trackException({ error: this.state.error })
      appInsights.flush() // Send all telemetry immediately to avoid losing it due to page navigation
      return (
        <Layout>
          <div style={{ padding: "50px", textAlign: "center" }}>
            <h1>Something went wrong.</h1>
            <p>We're sorry, but an unexpected error has occurred.</p>
            <details style={{ whiteSpace: "pre-wrap" }}>
              {this.state.error && this.state.error.toString()}
            </details>
          </div>
        </Layout>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
