import { ApplicationInsights } from "@microsoft/applicationinsights-web"

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: `${process.env.GATSBY_APP_INSIGHTS_INSTRUMENTATION_KEY}`,
    cookieCfg: { enabled: false },
  },
})

appInsights.loadAppInsights()

export default appInsights
